<template>
  <header class="relative inset-x-0 top-0">
    <Navigation/>
  </header>
</template>

<script setup>
  // import { computed } from 'vue';
  // import { useRoute } from 'vue-router';
  import Navigation from '@components/html-structure/navigation.vue';

  // const route = useRoute();
  // const isHomePage = computed(() => route.path === '/');
</script>